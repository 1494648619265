<template>
  <div class="select-none">
    <p v-if="isPrice" class="text-amber-300/70 group-hover:text-amber-300 font-bold flex justify-center items-center gap-1">
      <CurrencyYenIcon class="size-5" />
      <span> {{ props.card.price.number }} </span>
      <span> {{ props.card.rare }} </span>
    </p>

    <div class="relative cursor-pointer group">
      <CardCover
        :src="props.card.scraper"
        :rare="props.card.rare"
        :isEffect="true"
        :class="{
          grayscale: props.disabled
        }"
      />

      <!-- title -->
      <div
        class="absolute z-[2] bottom-0 w-full px-1 pb-1 pt-4 flex items-end gap-1"
        style="transform: translate3d(0, 0, 0)"
        :class="{
          'bg-mask group-hover:bg-none': !matchSize('md') && !props.onlyCover
        }"
      >
        <div
          v-if="!matchSize('md') && !props.onlyCover"
          class="grow-1 w-full min-w-0 group-hover:opacity-0 will-change-[opacity] transition-opacity select-none"
        >
          <p class="text-sm font-mono text-zinc-300 truncate">{{ props.card.id }}</p>
          <div class="font-bold text-white truncate">{{ translate(props.card, 'title') }}</div>
        </div>

        <div
          v-if="props.action === 'count'"
          class="flex-none rounded-full p-1 shadow-xl will-change-[background,shadow] transition-all"
          :class="{
            ...countClass
          }"
        >
          <span
            class="size-6 text-mono font-bold grid content-center text-center select-none"
            :class="{
              'text-sm !w-auto': props.count.includes('/')
            }"
          >
            {{ props.count }}
          </span>
        </div>

        <button
          v-else-if="props.action === 'button'"
          @click.stop="
            () => {
              doVibrate('cardDeck', setting.vibrate)
              doCardDeck('add', props.card)
            }
          "
          :class="{
            'group-hover:bg-zinc-800 group-hover:shadow group-hover:shadow-zinc-800/50': !getCountInCardDeck(props.card?.id),
            'bg-cyan-600 shadow-cyan-800/50': getCountInCardDeck(props.card?.id),
            'bg-zinc-800 shadow shadow-zinc-800/50': matchSize('md') && !getCountInCardDeck(props.card?.id),
            'ml-auto': matchSize('md')
          }"
          class="flex-none rounded-full p-1 shadow-xl will-change-[background,shadow] transition-all"
        >
          <span v-if="getCountInCardDeck(props.card?.id)" class="size-7 text-mono font-bold text-white grid content-center text-center select-none">
            {{ getCountInCardDeck(props.card?.id) }}
          </span>
          <InboxArrowDownIcon v-else class="size-7 text-white stroke-2" />
        </button>
      </div>
    </div>

    <slot name="cardRare" :card="props.card" />
    <slot name="editNote" :card="props.card" />
  </div>
</template>

<script setup>
import { InboxArrowDownIcon, CurrencyYenIcon } from '@heroicons/vue/24/outline'
import { ChartBarIcon } from '@heroicons/vue/24/solid'

const preferenceStore = usePreferenceStore()
const { translate } = preferenceStore
const { setting } = storeToRefs(preferenceStore)
const { doCardDeck, getCountInCardDeck } = useDeckStore()

const { matchSize } = useRwd()
const { doVibrate } = useReactiveVibrate()

const props = defineProps({
  card: {
    type: Object,
    default: {}
  },
  disabled: {
    type: Boolean,
    default: false
  },
  action: {
    type: String,
    default: 'button'
  },
  count: {
    type: String,
    default: '0'
  },
  countColor: {
    type: String,
    default: 'zinc'
  },
  isPrice: {
    type: Boolean,
    default: false
  },
  onlyCover: {
    type: Boolean,
    default: false
  }
})

const countClass = computed(() => {
  let c = {}
  if (props.countColor && true) {
    c[`bg-${props.countColor}-600 shadow-${props.countColor}-800/50 text-white`] = true
  } else {
    if (props.disabled) {
      c['bg-zinc-700 shadow-zinc-800/50 text-white/50'] = true
    } else {
      if (props.count.includes('/') && props.count.split('/')[0] === props.count.split('/')[1])
        c['bg-purple-700 shadow-purple-800/50 text-white'] = true
      else c[`bg-zinc-100 shadow-zinc-100/50 text-zinc-900`] = true
    }
  }
  return c
})
</script>

<style lang="sass" scoped>
.bg-image
  @apply bg-center bg-no-repeat bg-cover h-full w-full relative

// .hover-mask::after
//   content: ''
//   background-image: linear-gradient(rgba(18, 18, 18, 0) 0%, rgba(6, 6, 6, 0.6) 60%, rgba(0, 0, 0, 0.7) 100%)
//   @apply inset-0 absolute w-full h-full block group-hover:opacity-0 will-change-[opacity] transition-opacity rounded-card


.bg-mask
  background-image: linear-gradient(transparent, rgba(0,0,0, 0.6) 40%, rgba(0, 0, 0, 0.75) 100%)

.init
  @apply bg-green-600 shadow-green-800/50
  @apply bg-red-600 shadow-red-800/50
</style>
