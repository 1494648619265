<template>
  <template v-if="isIos || isAndroid || !setting.coverFollow">
    <div class="relative">
      <NuxtImg
        :src="props.src || 'cover'"
        loading="lazy"
        preload
        class="rounded-card flex-none shadow-lg aspect-card min-w-0 w-full default-transition bg-image select-none"
        :style="{
          'background-image': 'url(/images/loading.png)'
        }"
        :placeholder="10"
        size="sm:100vw md:50vw lg:600px"
      />

      <!-- <img
        :src="cardCover(props.src, { modifiers: { width: 582, height: 800 } }) || '/images/loading.png'"
        preload
        class="rounded-card flex-none shadow-lg aspect-card min-w-0 w-full default-transition bg-image select-none"
        :style="{
          'background-image': 'url(/images/loading.png)'
        }"
      /> -->
      <!-- effect -->
      <template v-if="props.isEffect && setting.coverEffect && user.role === 'member'">
        <div v-if="effects.includes('breath')" class="breath-word" />
        <div v-if="effects.includes('shine')" class="glass-shine" />
        <div v-if="effects.includes('ray')" class="ray" />
        <div v-if="effects.includes('diamond')" class="diamond-shine" />
      </template>
    </div>
  </template>

  <template v-else>
    <div ref="cardWrapper" class="cardWrapper">
      <div ref="card" class="overflow-hidden rounded-card relative">
        <NuxtImg
          :src="props.src || 'cover'"
          loading="lazy"
          preload
          class="rounded-card flex-none shadow-lg aspect-card min-w-0 w-full default-transition bg-image select-none"
          :style="{
            'background-image': 'url(/images/loading.png)'
          }"
          :placeholder="10"
          size="sm:100vw md:50vw lg:600px"
        />

        <!-- <img
          :src="cardCover(props.src, { modifiers: { width: 582, height: 800 } }) || '/images/loading.png'"
          preload
          class="rounded-card flex-none shadow-lg aspect-card min-w-0 w-full default-transition bg-image select-none"
          :style="{
            'background-image': 'url(/images/loading.png)'
          }"
        /> -->

        <!-- effect -->
        <template v-if="props.isEffect && setting.coverEffect && user.role === 'member'">
          <div v-if="effects.includes('breath')" class="breath-word" />
          <div v-if="effects.includes('shine')" class="glass-shine" />
          <div v-if="effects.includes('ray')" class="ray" />
          <div v-if="effects.includes('diamond')" class="diamond-shine" />
        </template>

        <div ref="highlight" class="highlight" />
      </div>
    </div>
  </template>
</template>

<script setup>
const { isIos, isAndroid } = useDevice()
const props = defineProps({
  src: {
    type: String,
    default: ''
  },

  rare: {
    type: String,
    default: ''
  },

  isEffect: {
    type: Boolean,
    default: false
  }
})

const { setting } = storeToRefs(usePreferenceStore())
const { user } = storeToRefs(useGlobalStore())

const cardCover = (c, m) => useImageProxy(c, m)

const effects = computed(() => {
  const f = []
  if (['SP', 'SSP'].includes(props.rare) || ['P$', 'P+$'].map(e => new RegExp(e, 'gmi').test(props.rare)).reduce((a, c) => c || a, false)) {
    f.push('breath')
  }
  if (['RRR'].includes(props.rare)) {
    f.push('shine')
  }
  if (['SR', 'SEC', 'XR', 'VR', ''].includes(props.rare)) {
    f.push('ray')
  }
  if (
    ['SP', 'SSP', 'OFR'].includes(props.rare) ||
    ['P$', 'P+$', '^SP'].map(e => new RegExp(e, 'gmi').test(props.rare)).reduce((a, c) => c || a, false)
  ) {
    f.push('diamond')
  }

  return f
})

////////////////////////////////////////////////////////////////
// card cover hover event
const cardWrapper = ref()
const card = ref()
const highlight = ref()

const mostX = 10 // 10 or -10
const mostY = 10 // 10 or -10

const moveEvent = e => {
  // remove transition
  card.value.style.transition = 'none'
  highlight.value.style.transition = 'none'

  const x = e.offsetX
  const y = e.offsetY
  const { width, height } = cardWrapper.value.getBoundingClientRect()
  const halfWidth = width / 2
  const halfHeight = height / 2

  // calculate angle
  const rotationY = ((x - halfWidth) / halfWidth) * mostX
  const rotationX = ((y - halfHeight) / halfHeight) * mostY

  // set rotation
  card.value.style.transform = `rotateY(${rotationY}deg) rotateX(${rotationX}deg)`
  highlight.value.style.left = `${(rotationY / mostX) * 50 * -1}%`
  highlight.value.style.top = `${(rotationX / mostY) * 50 * -1}%`
}

const leaveEvent = () => {
  // add transition back
  card.value.style.transition = 'transform 0.5s ease-in-out'
  card.value.style.transform = `rotateY(0) rotateX(0)`
  highlight.value.style.transition = 'left 0.5s ease-in-out, top 0.5s ease-in-out'

  // add default position back to highlight
  highlight.value.style.left = `-30%`
  highlight.value.style.top = `-20%`
}

onMounted(() => {
  if (!(isIos || isAndroid || !setting.value.coverFollow)) {
    cardWrapper.value.addEventListener('mousemove', moveEvent)
    cardWrapper.value.addEventListener('mouseleave', leaveEvent)
    leaveEvent()
  }
})
</script>

<style scoped>
.cardWrapper {
  perspective: 1000px;
}

.cardWrapper * {
  pointer-events: none;
}

.highlight {
  z-index: 1;
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.084);
  filter: brightness(80%) blur(20px);
  left: -30%;
  top: -20%;
}
</style>
