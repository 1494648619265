
import * as imgproxyRuntime$ELLFLOVvyl from '/vercel/path0/BottleNekoV2/providers/imgproxy.js'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgproxy",
  "domains": [
    "jasonxddd.me:7001"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imgproxy']: { provider: imgproxyRuntime$ELLFLOVvyl, defaults: {"domain":"https://jasonxddd.me:7001/imgproxy","imagePath":"local://","key":"d2a98b0544931ad902bacfbecb62378c8347d3ca4363fb796c4f334fcc17b2d474625641db697fafc60e634e28203defa4a7b6bf415a9bd230d6baaf74a1883d","salt":"aca2682b9a2a147d3e7a9cc37dd8f72916455d0deb71a2d5ab8d40bacb8901ee21a70da739fd1fb4302184c943c5b51dbaff339cd92a075527b12460c40c01f1"} }
}
        