import * as hash from 'hash.js'
import { Buffer } from 'buffer'
import { joinURL } from 'ufo'
import { createOperationsGenerator } from '@nuxt/image/dist/runtime/utils/index'

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    resize: 'rs',
    size: 's',
    fit: 'rt',
    width: 'w',
    height: 'h',
    dpr: 'dpr',
    enlarge: 'el',
    extend: 'ex',
    gravity: 'g',
    crop: 'c',
    padding: 'pd',
    trim: 't',
    rotate: 'rot',
    quality: 'q',
    maxBytes: 'mb',
    background: 'bg',
    backgroundAlpha: 'bga',
    blur: 'bl',
    sharpen: 'sh',
    watermark: 'wm',
    preset: 'pr',
    cacheBuster: 'cb',
    stripMetadata: 'sm',
    stripColorProfile: 'scp',
    autoRotate: 'ar',
    filename: 'fn',
    format: 'f',
  },
  formatter: (key, value) => `${key}:${value}`,
})

const urlSafeBase64 = (string) => {
  return Buffer.from(string).toString('base64').replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const hexDecode = (hex) => Buffer.from(hex, 'hex')

const sign = (salt, target, secret) => {
  const hmac = hash.hmac(hash.sha256, hexDecode(secret))
  hmac.update(hexDecode(salt))
  hmac.update(target)
  return urlSafeBase64(hmac.digest())
}

const defaultModifier = {
  fit: 'fill',
  width: 0,
  height: 0,
  gravity: 'no',
  enlarge: 1,
  format: 'png',
}

export function getImage(src, { modifiers, domain, imagePath, key, salt } = {}, { options, $img }) {
  const mergeModifiers = {
    ...defaultModifier,
    ...modifiers,
  }

  if (src.endsWith('cover')) {
    return {
      url: 'images/loading.png',
    }
  }

  const url = `${imagePath}/${src}.png`
  const type = 'png'
  const prefix = joinURL('/', operationsGenerator(mergeModifiers))

  const encrypted = `${prefix}/${urlSafeBase64(url)}.${type}`
  const signature = sign(salt, encrypted, key)

  return {
    url: joinURL(domain, `${signature}${encrypted}`),
  }
}
